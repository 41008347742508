<template>
  <div
    v-if="license"
    :class="{ 'license-deactivated': !license.is_active }"
    style="line-height: 2.75rem"
  >
    <table class="right-padded-cells">
      <tr>
        <td>
          <strong> Cluster GUID </strong>
        </td>
        <td>
          <samp>
            {{ license.cluster_guid }}
          </samp>
        </td>
        <td>
          <label v-if="isExpired(license)" class="label label-danger">
            License Expired
          </label>
        </td>
      </tr>
      <tr>
        <td>
          <strong> Valid From </strong>
        </td>
        <td>
          {{ license.valid_from | moment("LLLL") }}
        </td>
      </tr>
      <tr>
        <td>
          <strong> Expires At </strong>
        </td>
        <td>
          {{ license.expires_at | moment("LLLL") }}
        </td>
      </tr>
      <tr v-if="showEnforcementType && license.enforcement_type">
        <td>
          <strong> Enforcement Type </strong>
        </td>
        <td>
          {{ license.enforcement_type }}
        </td>
      </tr>
      <tr v-if="license.drive_capacity_gb">
        <td>
          <strong> Raw Flash capacity </strong>
        </td>
        <td>
          {{ (license.drive_capacity_gb * 1000000000) | prettyBytes }}
        </td>
      </tr>
      <tr v-if="license.usable_capacity_gb">
        <td>
          <strong> Usable Flash capacity </strong>
        </td>
        <td>
          {{ (license.usable_capacity_gb * 1000000000) | prettyBytes }}
        </td>
      </tr>
      <tr v-if="license.obs_capacity_gb">
        <td>
          <strong> OBS Capacity for Data Tiering </strong>
        </td>
        <td>
          {{ (license.obs_capacity_gb * 1000000000) | prettyBytes }}
        </td>
      </tr>
      <tr v-if="license.obs_capacity_for_data_protection_gb">
        <td>
          <strong> OBS Capacity for Data Protection </strong>
        </td>
        <td>
          {{
            (license.obs_capacity_for_data_protection_gb * 1000000000)
              | prettyBytes
          }}
        </td>
      </tr>
      <tr v-if="license.xps_tb">
        <td>
          <strong>
            {{ SKUs.WekaXPS }}
          </strong>
        </td>
        <td>
          {{ (license.xps_tb * TB_TO_B) | prettyBytes }}
        </td>
      </tr>
      <tr v-if="license.xcl_tb">
        <td>
          <strong>
            {{ SKUs.WekaXCL }}
          </strong>
        </td>
        <td>
          {{ (license.xcl_tb * TB_TO_B) | prettyBytes }}
        </td>
      </tr>
      <tr v-if="license.xos_tb">
        <td>
          <strong>
            {{ SKUs.WekaXOS }}
          </strong>
        </td>
        <td>
          {{ (license.xos_tb * TB_TO_B) | prettyBytes }}
        </td>
      </tr>
      <tr v-if="license.wpsw_tb">
        <td>
          <strong>
            {{ SKUs.WekaWPSW }}
          </strong>
        </td>
        <td>
          {{ (license.wpsw_tb * TB_TO_B) | prettyBytes }}
        </td>
      </tr>
      <tr v-if="license.dpo_tb">
        <td>
          <strong>
            {{ SKUs.WekaDPO }}
          </strong>
        </td>
        <td>
          {{ (license.dpo_tb * TB_TO_B) | prettyBytes }}
        </td>
      </tr>
      <tr v-if="license.dto_tb">
        <td>
          <strong>
            {{ SKUs.WekaDTO }}
          </strong>
        </td>
        <td>
          {{ (license.dto_tb * TB_TO_B) | prettyBytes }}
        </td>
      </tr>
      <tr v-if="license.deo_tb">
        <td>
          <strong>
            {{ SKUs.WekaDEO }}
          </strong>
        </td>
        <td>
          {{ (license.deo_tb * TB_TO_B) | prettyBytes }}
        </td>
      </tr>
      <tr v-if="license.end_user">
        <td>
          <strong> End User </strong>
        </td>
        <td>
          {{ license.end_user }}
        </td>
      </tr>
      <tr v-if="license.comment">
        <td>
          <strong> Comment </strong>
        </td>
        <td>
          {{ license.comment }}
        </td>
      </tr>
      <tr v-if="showDataReduction">
        <td>
          <strong> Data Reduction </strong>
        </td>
        <td>
          {{ license.data_reduction ? "Enabled" : "Disabled" }}
        </td>
      </tr>
      <tr v-if="showCreator && license.created_by_name">
        <td>
          <strong> Created by </strong>
        </td>
        <td>
          {{ license.created_by_name }}
        </td>
      </tr>
    </table>
    <div v-if="showLicenseId" class="small subtext" style="color: #aaa">
      License Id {{ license.id }}
    </div>
    <div
      v-if="license.entitlement_id && showEntitlementId"
      class="small subtext"
      style="color: #aaa"
    >
      Entitlement Id {{ license.entitlement_id }}
    </div>
    <div
      v-if="license.sf_license_id && showSfLicenseId"
      class="small subtext"
      style="color: #aaa"
    >
      SF License Id {{ license.sf_license_id }}
    </div>
    <div
      v-if="license.sf_asset_id && showSfAssetId"
      class="small subtext"
      style="color: #aaa"
    >
      SF Asset Id {{ license.sf_asset_id }}
    </div>
    <div class="subtext">
      <span v-if="showIsActive && !isExpired(license)">
        <span
          v-if="license.is_active"
          class="label label-success"
          style="margin-left: 0"
          >ACTIVE</span
        >
        <span
          v-if="!license.is_active"
          class="label label-danger"
          style="margin-left: 0"
          >INACTIVE</span
        >
      </span>
      <span v-if="createdAt" class="small" style="color: #aaa">
        Created at {{ license.created_at | moment("LLL") }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
table.right-padded-cells {
  tr {
    td {
      padding-right: 1.5rem;
    }
  }
}
.license-deactivated {
  opacity: 0.5;
}
</style>

<script>
import moment from "moment";
import { SKUs, TB_TO_B } from "@/utils";

export default {
  name: "license-details",
  computed: {
    SKUs() {
      return SKUs;
    },
    TB_TO_B() {
      return TB_TO_B;
    },
  },

  props: {
    license: {
      type: Object,
    },
    createdAt: {
      type: Boolean,
      default: true,
    },
    showIsActive: {
      type: Boolean,
      default: false,
    },
    showCreator: {
      type: Boolean,
      default: false,
    },
    showDataReduction: {
      type: Boolean,
      default: false,
    },
    showEntitlementId: {
      type: Boolean,
      default: false,
    },
    showLicenseId: {
      type: Boolean,
      default: false,
    },
    showEnforcementType: {
      type: Boolean,
      default: false,
    },
    showSfLicenseId: {
      type: Boolean,
      default: false,
    },
    showSfAssetId: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isExpired(license) {
      return (
        license.expires_at && moment(license.expires_at).isBefore(moment())
      );
    },
  },
};
</script>
